@font-face {
  font-family: "Regular";
  src: local("KintoSans-Regular"),
    url("./fonts/KintoSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Bold";
  src: local("KintoSans-Bold"),
    url("./fonts/KintoSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Meiryo";
  src: url("./fonts/meiryo.ttf") format("truetype");
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* @hack: datagridでedit後付与されるクラスを無効化(正当な方法が分からず) */
.MuiDataGrid-cell--editing {
  box-shadow: initial !important;
  background-color: initial !important;
  padding-left: initial !important;
}

.orange-icon {
  color: #eb811a;
}

.grayedOut {
  color: gray;
}
